import React from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "./client"
import { CartProvider } from "./cartProvider"
import { ProductsProvider } from "./productsProvider"
export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <ProductsProvider>
        <CartProvider>
          {element}
        </CartProvider>
      </ProductsProvider>
    </ApolloProvider>
  )
}
